import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AuthService, StorageService } from './amm-share/services';
import { SseService } from './amm-share/services/sse.service';
import { CommonStore, ShoppingInfoStore } from './amm-share/stores';
import { MessagingStore } from './amm-share/stores/amm-message.store';
import { Subject, timer } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'amm-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet, ToastModule],

  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Amm-Buyer';

  private shoppingCartStore = inject(ShoppingInfoStore);
  public messagingStore = inject(MessagingStore);

  private commonStore = inject(CommonStore);
  private sseService = inject(SseService);
  private storageService = inject(StorageService);
  private authService = inject(AuthService);

  private destory$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.commonStore.loadAllCategory();
    //this.messagingStore.loadConversations();
    this.commonStore.loadStatesByCountry('US');
    this.commonStore.loadAllCountries();
    this.commonStore.loadAllCancelReasons();
    const jwt = this.storageService.getJWT();
    if (jwt) {
      this.sseService.connectToSSE(jwt);
    }
    timer(0,environment.jwtExpireCheckInterval).pipe().subscribe(() => {
      const jwt = this.storageService.getJWT();
      if (jwt) {
        const decodedJwt = jwtDecode(jwt);
        const isExpired = new Date().getTime() > (decodedJwt?.exp || 0)*1000;
        if (isExpired) {
          this.authService.logOut();
        }
      }
      
    })
  }

  ngOnDestroy(): void {
    this.shoppingCartStore.saveShoppingCartsToDB();
    this.sseService.abortSSE();
    this.destory$.next(true);
    this.destory$.complete();
  }
  @HostListener('window:storage')
  onLocalStorageChange() {}
}
