<p-toast
  #toast
  [position]="position()"
  [key]="toastKey()"
  [style]="{ width: 'fit-content' }"
  (onClose)="toastClose($event)"
  preventOpenDuplicates="true"

>
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    <div
      class="flex flex-row gap-2 product-operation-toast-wrapper items-center"
    >
      <div class="font-medium line-height-3 text-base toast-text">
        {{ message.detail }}
      </div>
      @if (message?.data?.enableActionBtn) {
        <div class="flex toast-text items-center justify-center cursor-pointer">
          <div class="flex" (click)="onBtnClick(message.data)">
            <span
              class="material-symbols-outlined self-center"
              style="font-size: 24px"
            >
              {{ iconName() }}
            </span>
            {{ btnLabel() }}
            @if (message?.data?.showItemDesc) {
              <span class="self-center"
                >({{ getDisplayText(message?.data?.itemDesc) + "..." }})</span
              >
            }
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-toast>
