import { ErrorHandler, inject, Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn:'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    private messageService = inject(MessageService)
    handleError(error: any): void {
      console.log("error handler is called");
      //TODO: add this error message to backend API 
      this.messageService.add({
               summary: 'Error',
               severity: 'error',
               detail: error.message ?? "Unkown error please retry" ,
               key: 'general-notification',
             });
    }

}