import { Routes } from '@angular/router';
import { HomeComponent } from './amm-pages/home/home.component';
import { addressResolver, forceLoginGuard } from './amm-share/guards';

const routeConfig: Routes = [
  {
    path: 'message',
    canActivate: [forceLoginGuard],
    loadComponent: () =>
      import('../app/amm-pages/page-message/page-message.component').then(
        (m) => m.PageMessageComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            '../app/amm-share/components/no-converstaion-selected/no-converstaion-selected.component'
          ).then((m) => m.NoConverstaionSelectedComponent),
        outlet: 'messageConversations',
      },
      {
        path: ':topmsgId',
        loadComponent: () =>
          import(
            '../app/amm-pages/message-content/message-content.component'
          ).then((m) => m.MessageContentComponent),
        outlet: 'messageConversations',
      },
    ],
    title: 'Message',
  },
  {
    path: 'check-out',
    loadComponent: () =>
      import('../app/amm-pages/check-out/check-out.component').then(
        (m) => m.CheckOutComponent
      ),
    resolve: { shippAddressOption: addressResolver },
    title: 'check out',
  },
  {
    path: 'page-policy',
    loadComponent: () =>
      import('../app/amm-pages/page-policy/page-policy.component').then(
        (m) => m.PagePolicyComponent
      ),
    title: 'Page policy',
  },
  {
    path: 'legal-docs/:documentType',
    loadComponent: () =>
      import('../app/amm-pages/legal-doc/legal-doc.component').then(
        (m) => m.LegalDocComponent
      ),
    title: 'Legal doc',
  },
  // {
  //   path: 'help-center-article/:documentDir',
  //   loadComponent: () =>
  //     import('../app/amm-pages/legal-doc/legal-doc.component').then(
  //       (m) => m.LegalDocComponent,
  //     ),
  //   title: 'Help center article',
  // },
  {
    path: 'help-center-article/:documentDir/:fileName',
    loadComponent: () =>
      import(
        '../app/amm-pages/help-center-article/help-center-article.component'
      ).then((m) => m.HelpCenterArticleComponent),
    title: 'Help center article',
  },
  {
    path: 'help-center',
    loadComponent: () =>
      import('../app/amm-pages/help-center/help-center.component').then(
        (m) => m.HelpCenterComponent
      ),
    title: 'Help center',
  },
  {
    path: 'register',
    loadComponent: () =>
      import('../app/amm-pages/page-register/page-register.component').then(
        (m) => m.PageRegisterComponent
      ),
    title: 'Register',
  },
  {
    path: 'quick-product-view',
    loadComponent: () =>
      import(
        '../app/amm-pages/product-quick-view/product-quick-view.component'
      ).then((m) => m.ProductQuickViewComponent),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../app/amm-pages/main-page/main-page.component').then(
            (m) => m.MainPageComponent
          ),
        outlet: 'mainBody',
      },
      {
        path: 'search-result',
        loadComponent: () =>
          import('../app/amm-pages/search-result/search-result.component').then(
            (m) => m.SearchResultComponent
          ),
        outlet: 'mainBody',
      },
      {
        path: 'favorites',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/favorites/favorites.component').then(
            (m) => m.FavoritesComponent
          ),
        outlet: 'mainBody',
        title: 'Favorites',
      },
      {
        path: 'shopping-carts',
        loadComponent: () =>
          import(
            '../app/amm-pages/shopping-carts/shopping-carts.component'
          ).then((m) => m.ShoppingCartsComponent),
        outlet: 'mainBody',
        title: 'Shopping Carts',
      },
      {
        path: 'orders',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/orders/orders.component').then(
            (m) => m.OrdersComponent
          ),
        outlet: 'mainBody',
        title: 'Orders',
      },
      {
        path: 'orders/:poNumber',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/order-details/order-details.component').then(
            (m) => m.OrderDetailsComponent
          ),
        outlet: 'mainBody',
        title: 'Orders',
      },
      {
        path: 'details/:code',
        loadComponent: () =>
          import('../app/amm-pages/details/details.component').then(
            (m) => m.DetailsComponent
          ),
        outlet: 'mainBody',
        title: 'Product details',
      },
      {
        path: 'store/:code',
        loadComponent: () =>
          import('../app/amm-pages/page-store/page-store.component').then(
            (m) => m.PageStoreComponent
          ),
        outlet: 'mainBody',
        title: 'Store',
      },
      {
        path: 'login-security',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import(
            '../app/amm-pages/login-security/login-security.component'
          ).then((m) => m.LoginSecurityComponent),
        outlet: 'mainBody',
        title: 'Login security',
      },
      {
        path: 'address',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/page-address/page-address.component').then(
            (m) => m.PageAddressComponent
          ),
        outlet: 'mainBody',
        title: 'Address',
      },
      {
        path: 'order-confirm',
        loadComponent: () =>
          import('../app/amm-pages/order-confirm/order-confirm.component').then(
            (m) => m.OrderConfirmComponent
          ),
        outlet: 'mainBody',
        title: 'Order Confirmation',
      },
    ],
    title: 'Ammall Shopping',
  },
];

export default routeConfig;
