import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { StatusInfo } from '../models';
import { AuthService } from '../services';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const messageService = inject(MessageService);
  const authService = inject(AuthService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorBody = error.error;
      let msg = error.message;
      let statusMsg = '';
      if (
        errorBody &&
        errorBody['statusList'] &&
        Array.isArray(errorBody['statusList'])
      ) {
        const errorStatusList = errorBody['statusList'] as Array<StatusInfo>;

        for (let info of errorStatusList) {
          if (info.error) {
            statusMsg += info.desc + ';';
          }
        }
        msg = statusMsg;
      }

      // if the http returned 403, then we should re-try the login
      if (error.status == HttpStatusCode.Unauthorized) {
        // log out the user
        authService.logOut();
       return next(req);
      }

      // show error message toast on UI
      messageService.add({
        summary: 'Error',
        severity: 'error',
        detail: msg ?? 'Unkown error please contact support@ammall.com',
        key: 'general-notification',
      });
      return throwError(() => new Error(msg));
    })
  );
};
