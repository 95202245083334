import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommonStore } from '../stores';

export const addressResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<void> => {
  const commonStore = inject(CommonStore);
  commonStore.loadAllAddresses();
  commonStore.loadBuyerInfo();

  return of(undefined);
};
