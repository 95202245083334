import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { enableProdMode, ErrorHandler } from '@angular/core';
import {
  bootstrapApplication,
  provideProtractorTestingSupport,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppComponent } from './app/app.component';
import routeConfig from './app/app.routes';
import { environment } from './environments/environment';

import {
  provideNgxStripe,
} from 'ngx-stripe';
import { GlobalErrorHandler } from './app/amm-share/handlers/globalErrorHandler';
import { errorInterceptor } from './app/amm-share/interceptors/httpError.interceptor';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    provideProtractorTestingSupport(),
    provideRouter(routeConfig, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([errorInterceptor])),
    provideOAuthClient(),
    provideNgxStripe(),
    MessageService,
    DynamicDialogConfig,
    DialogService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}    
  ],
}).catch((err) => 
  console.error(err)
);
