import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Message } from 'primeng/api';
import { AmmFooterComponent } from '../../amm-share/components/amm-footer/amm-footer.component';
import { AmmToastWithActionComponent } from '../../amm-share/components/amm-toast-with-action/amm-toast-with-action.component';
import { GlobalNavWithIndicatorComponent } from '../../amm-share/components/global-nav-with-indicator/global-nav-with-indicator.component';
import { ToastActionEvent } from '../../amm-share/models';
import { NavIndicatorService } from '../../amm-share/services';
import { AmmToastService } from '../../amm-share/services/amm-toast.service';
import { ShoppingInfoStore } from '../../amm-share/stores';

@Component({
  selector: 'amm-home',
  standalone: true,
  imports: [
    CommonModule,
    AmmFooterComponent,
    RouterOutlet,
    GlobalNavWithIndicatorComponent,
    AmmToastWithActionComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  public navIndicatorService = inject(NavIndicatorService);
  public shoppingCartInfoStore = inject(ShoppingInfoStore);

  private ammToastService = inject(AmmToastService);
  /**
   *
   */
  constructor() {
    this.navIndicatorService.disableNavIndicator();
  }
  ngOnInit(): void {
  }

  onToastClose(event: Message) {
    this.ammToastService.onToastClose.next(event.data);
  }
  onBtnAction(toastActionEvent: ToastActionEvent) {
    this.ammToastService.onToastActionClicked.next(toastActionEvent);
  }
}
