<div class="flex flex-col">
  <amm-global-nav-with-indicator
  [showNavIndicator]="navIndicatorService.getNavIndicator()"
  (onNavIndicatorClick)="navIndicatorService.disableNavIndicator()"
></amm-global-nav-with-indicator>

<router-outlet name="mainBody"> </router-outlet>

<div class="pt-12 pr-16 pl-16 pb-8 mt-auto"><amm-footer></amm-footer></div>
</div>


<amm-toast-with-action
  toastKey="product-operation-toast"
  (onToastClose)="onToastClose($event)"
  (onBtnAction)="onBtnAction($event)"
></amm-toast-with-action>

