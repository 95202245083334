import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { StorageService } from './amm-share/services';
import { SseService } from './amm-share/services/sse.service';
import { CommonStore, ShoppingInfoStore } from './amm-share/stores';

@Component({
  selector: 'amm-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet],

  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Amm-Buyer';

  private shoppingCartStore = inject(ShoppingInfoStore);
  private commonStore = inject(CommonStore);
  private sseService = inject(SseService);
  private storageService = inject(StorageService);

  constructor() {}

  ngOnInit(): void {
    this.commonStore.loadAllCategory();
    const jwt = this.storageService.getJWT();
    if (jwt) {
      this.sseService.connectToSSE(jwt);
    }
  }

  ngOnDestroy(): void {
    this.shoppingCartStore.saveShoppingCartsToDB();
    this.sseService.abortSSE();
  }
  @HostListener('window:storage')
  onLocalStorageChange() {}
}
