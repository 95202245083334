import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  input,
  output,
  ViewChild,
} from '@angular/core';
import {
  Toast,
  ToastCloseEvent,
  ToastModule,
  ToastPositionType,
} from 'primeng/toast';
import { MATERIAL_ICON_NAME } from '../../constants';
import { AmmToastService } from '../../services/amm-toast.service';
import { ToastActionEvent } from '../../models';

@Component({
  selector: 'amm-toast-with-action',
  standalone: true,
  imports: [CommonModule, ToastModule],
  templateUrl: './amm-toast-with-action.component.html',
  styleUrl: './amm-toast-with-action.component.scss',
})
export class AmmToastWithActionComponent implements AfterViewInit {
  @ViewChild('toast', { read: Toast }) toastComponent!: Toast;
  public position = input<ToastPositionType>('bottom-center');
  public toastKey = input('default');
  public iconName = input(MATERIAL_ICON_NAME.undo);
  public btnLabel = input('Undo');
  public descripionPrefixStopPosition = input(50);
  public onToastClose = output<any>();
  public onBtnAction = output<ToastActionEvent>();

  private ammToastService = inject(AmmToastService);

  ngAfterViewInit(): void {
    console.log(this.toastComponent);
    if (this.toastComponent) {
      this.ammToastService.toastComponent = this.toastComponent;
    }
  }
  toastClose(event: ToastCloseEvent) {
    this.onToastClose.emit(event.message);
  }
  onBtnClick(messageData: any) {
    this.onBtnAction.emit(<ToastActionEvent> {
      messageData: messageData,
      toastComponent: this.toastComponent
    });
  }
  getDisplayText(description: string | undefined) {
    return description?.substring(0, this.descripionPrefixStopPosition());
  }
}
